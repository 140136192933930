import React from "react"
import Item from "./serviceItem"
import { Col, Container, Row } from "react-bootstrap"
import {
  faDollarSign,
  faCar,
  faRecycle,
  faPaste,
  faMapMarked,
  faHeart,
} from "@fortawesome/free-solid-svg-icons"

const Services = () => (
  <Container className="animated fadeInUp delay-0.2s">
    <Row>
      <Col lg="6" xs="12">
        <Item
          icon={faDollarSign}
          title="Service d'enlèvement gratuit"
          firstDescription="Aucun coût pour vous "
          secondDescription="Aucun frais ne vous sera facturé pas notre société. Notre service d'enlèvement d'épave est 100 % gratuit.."
        />
      </Col>
      <Col lg="6" xs="12">
        <Item
          icon={faPaste}
          title="Centre agréé par la préfecture"
          firstDescription="Sous contrôle de la préfecture"
          secondDescription="Nous disposons d'une équipe d’epavistes gratuit agréés VHU par la préfecture de l'essonne."
        />
      </Col>
    </Row>
    <Row>
      <Col lg="6" xs="12">
        <Item
          icon={faRecycle}
          title="Recycler votre voiture"
          firstDescription="Penser à l'environnement"
          secondDescription="A chaque enlèvement et destruction, nous respectons les normes écologiques afin d’éviter les pollutions.."
        />
      </Col>
      <Col lg="6" xs="12">
        <Item
          icon={faCar}
          title="Tout type de véhicule"
          firstDescription="Voiture, moto, camion ... "
          secondDescription="Quelle que soit la raison, le dépanneur et epaviste intervient pour tout type de véhicule peut importe sa forme : accidentés, trop ancien ou en panne."
        />
      </Col>
    </Row>
    <Row>
      <Col lg="6" xs="12">
        <Item
          icon={faMapMarked}
          title="Nous opérons près de chez vous"
          firstDescription="Au coté des franciliens "
          secondDescription="Epaviste Dulin, société renommée dans l’enlèvement d'épaves. Nous vous proposons nos services sur toute l'Ile-De-France."
        />
      </Col>
      <Col lg="6" xs="12">
        <Item
          icon={faHeart}
          title="Nos clients nous recommandent"
          firstDescription="Un service toujours proche du client "
          secondDescription="Que vous souhaitiez recycler votre véhicule ou vous en débarrasser définitivement, nous vous proposons un service rapide et un accompagnement sur-mesure."
        />
      </Col>
    </Row>
  </Container>
)

export default Services
