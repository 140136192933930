import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import {
  link,
  bodyMedia,
  box,
  fond,
  img,
  para,
} from "./styles/faqTab.module.css"
import logo from "../../image/faq_logo_epaviste_idf_dulin.jpg"
import Media from "react-bootstrap/Media"

const FaqTab = () => (
  <Container className={box}>
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0" className={fond}>
          LES QUESTIONS SOUVENT POSÉES
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Media>
              <img
                width={117}
                height={169}
                className={`${img} `}
                src={logo}
                alt="logo faq"
              />
              <Media.Body className={bodyMedia}>
                <h5>Foire aux questions</h5>
                <p className={para}>
                  Dans cette rubrique, vous trouverez les principales questions
                  que vous pourriez vous poser
                  <br />
                  Exemples des questions : QU'EST-CE QU'UNE ÉPAVE ?, DOIS-JE
                  PRÉPARER DES DOCUMENTS POUR LE JOUR DE L'ENLÈVEMENT ?, etc.
                </p>
                <p className={para}>
                  Rendez-vous sur cette page :{" "}
                  <Link className={link} to="/faq">
                    FAQ
                  </Link>
                </p>
              </Media.Body>
            </Media>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </Container>
)

export default FaqTab
