import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Media from "react-bootstrap/Media"
import PropTypes from "prop-types"
import {
  box,
  firstDescription,
  badge,
  secondDescription,
  title,
} from "./styles/serviceItem.module.css"
import { Col } from "react-bootstrap"
import Row from "react-bootstrap/Row"

const serviceItem = (props) => (
  <Media className={box}>
    <Row>
      <Col lg="3" xs={12}>
        <div className={badge}>
          <FontAwesomeIcon icon={props.icon} />
        </div>
      </Col>
      <Col lg={{ span: 8, offset: 1 }} xs={12}>
        <Media.Body>
          <h5 className={title}>{props.title}</h5>
          <p className={firstDescription}>{props.firstDescription}</p>
          <p className={secondDescription}>{props.secondDescription}</p>
        </Media.Body>
      </Col>
    </Row>
  </Media>
)

serviceItem.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  firstDescription: PropTypes.string.isRequired,
  secondDescription: PropTypes.string.isRequired,
}

export default serviceItem
