import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { Col, Container, Row } from "react-bootstrap"
import {
  box,
  backgroundOptions,
  sizeBackground,
} from "./styles/slider.module.css"

const Slider = () => {
  const data = useStaticQuery(graphql`
    query {
      prefecture: file(
        relativePath: { eq: "epaviste-agree-prefecture-vhu.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      vhu: file(relativePath: { eq: "epaviste-vhu-dulin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      background: file(relativePath: { eq: "epaviste-auto-dulin.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="section"
      className={`${sizeBackground} ${backgroundOptions}`}
      fluid={data.background.childImageSharp.fluid}
    >
      <Container>
        <Row className={`${box} rounded animated fadeInUp delay-0.2s`}>
          <Col
            lg={{ span: 4, offset: 2 }}
            sm={{ span: 4, offset: 2 }}
            xs={{ span: 7 }}
          >
            <Img fluid={data.vhu.childImageSharp.fluid} alt="logo_centre_vhu" />
          </Col>
          <Col
            lg={{ span: 3, offset: 1 }}
            sm={{ span: 3, offset: 1 }}
            xs={{ span: 5 }}
          >
            <Img
              fluid={data.prefecture.childImageSharp.fluid}
              alt="logo_ministere_agriculture"
            />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  )
}

export default Slider
