import React from "react"
import Layout from "../components/common/layout"
import Slider from "../components/index/slider"
import Services from "../components/index/services"
import FaqTab from "../components/index/faqTab"
import Seo from "../components/common/seo"

const Index = () => (
  <Layout>
    <Seo />
    <Slider />
    <Services />
    <FaqTab />
  </Layout>
)

export default Index
